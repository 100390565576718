import {
  GET_QUESTIONS,
  GET_QUESTION_SUCCESS,
  GET_QUESTION_FAILURE,
  ADD_QUESTIONS,
  ADD_QUESTIONS_SUCCESS,
  ADD_QUESTIONS_FAILURE,
  GET_QUESTION_INDUSTRY_ID,
  GET_QUESTION_INDUSTRY_ID_SUCCESS,
  GET_QUESTION_INDUSTRY_ID_FAILURE,
  GET_QUESTION_VIDEOS,
  GET_QUESTION_VIDEOS_SUCCESS,
  GET_QUESTION_VIDEO_FAILURE,
  EDIT_QUESTION,
  EDIT_QUESTION_SUCCESS,
  EDIT_QUESTION_FAILURE
} from "./types"

const initialState = {
  questionData: false,
  loading: false,
  error: null,
  addQuestionsData: false,
  addQuestionsLoading: false,
  industryId: false,
  industryIdLoading: false,
  questionVideoData: false,
  questionVideoLoading: false,
  editQuestionData: false,
  editQuestionLoading: false
}
export default function getQuestion(state = initialState, action) {
  switch (action.type) {
    case GET_QUESTIONS:
      return {
        ...state,
        loading: true
      }
    case GET_QUESTION_SUCCESS:
      return {
        ...state,
        loading: false,
        questionData: action.data
      }
    case GET_QUESTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      }
    case ADD_QUESTIONS:
      return {
        ...state,
        addQuestionsLoading: true
      }
    case ADD_QUESTIONS_SUCCESS:
      return {
        ...state,
        addQuestionsLoading: false,
        addQuestionsData: action.data
      }
    case ADD_QUESTIONS_FAILURE:
      return {
        ...state,
        addQuestionsLoading: false,
        error: action.error
      }
    case GET_QUESTION_INDUSTRY_ID:
      return {
        ...state,
        industryIdLoading: true
      }
    case GET_QUESTION_INDUSTRY_ID_SUCCESS:
      return {
        ...state,
        industryIdLoading: false,
        industryId: action.data
      }
    case GET_QUESTION_INDUSTRY_ID_FAILURE:
      return {
        ...state,
        industryIdLoading: false,
        error: action.error
      }
    case GET_QUESTION_VIDEOS:
      return {
        ...state,
        questionVideoLoading: true
      }
    case GET_QUESTION_VIDEOS_SUCCESS:
      return {
        ...state,
        questionVideoLoading: false,
        questionVideoData: action.data
      }
    case GET_QUESTION_VIDEO_FAILURE:
      return {
        ...state,
        questionVideoLoading: false,
        error: action.error
      }
    case EDIT_QUESTION:
      return {
        ...state,
        editQuestionLoading: true
      }
    case EDIT_QUESTION_SUCCESS:
      return {
        ...state,
        editQuestionLoading: false,
        editQuestionData: action.data
      }
    case EDIT_QUESTION_FAILURE:
      return {
        ...state,
        editQuestionLoading: false,
        error: action.error
      }
    default:
      return state
  }
}
