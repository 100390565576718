import React, { useEffect, useState } from "react"
import CompanyLayout from "../../../layout/CompanyLayout"
import { Calendar, ConfigProvider } from "antd"
import "./style.scss"
import esES from "antd/es/locale/es_ES"
import enEn from "antd/es/locale/en_US"
import "dayjs/locale/es"
import moment from "moment"
import "moment/locale/es"
import MonthDropdown from "../../../components/MonthSelect"
import { Images } from "../../../theme/Images"
import Button from "../../../components/Button"
import { useDispatch, useSelector } from "react-redux"
import { getCalendar } from "./redux/action"
import ScheduleMeetingModal from "./components/ScheduleMeetingModal"
import { generateDates } from "../../../utils/functions"
import strings from "../../../translation"
import { getLanguage } from "../../../utils/functions"
import { Spinner } from "react-bootstrap"
import PasswordModal from "./components/PasswordModal"

const CompanyCalender = () => {
  const dispatch = useDispatch()
  const calendarData = useSelector(state => state.calendar.calendar)
  const lang = useSelector(state => state.login.language)
  const [showScheduleModal, setShowScheduleModal] = useState(false)
  const [showPasswordModal,setShowPasswordModal]=useState(false)
  const [selectedDate,setSelectedDate]=useState()
  const [dateId, setDateId] = useState(false)
  const [dates, setDates] = useState([])
  const [loading, setLoading] = useState(true)
  const onPanelChange = (value, mode) => {}

  useEffect(() => {
    getLanguage().then(lang => {
      moment.locale(lang, {
        week: {
          dow: 0
        }
      })
      strings.setLanguage(lang)
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    if (calendarData?.safety_meetings) {
      getDates(calendarData?.safety_meetings, calendarData?.plan_days)
    } else {
      setDates([])
    }
  }, [calendarData])

  const getDates = (startDate, dayGap) => {
    const numberOfYears = 99
    const dates = generateDates(startDate, numberOfYears, dayGap)
    setDates(dates)
  }

  const renderCustomHeader = ({ value, type, onChange }) => {
    const handlePrev = days => {
      const newValue = value.clone().subtract(days, type)
      onChange(newValue)
      setShowScheduleModal(false)
      setShowPasswordModal(false)
    }

    const handleNext = days => {
      const newValue = value.clone().add(days, type)
      onChange(newValue)
      setShowScheduleModal(false)
      setShowPasswordModal(false)
    }
    const isPreviousDate = () => {
      const currentDate = moment()
      const givenDate = moment(value.$d).format("YYYY-MM-DD")
      return currentDate.isBefore(givenDate, "day")
    }
    const getFifthYearDate = () => {
      const currentDate = moment()
      const fifthYearDate = moment(currentDate).add(4, "years")
      const newDate = moment(fifthYearDate)
        .month(11)
        .endOf("month")
        .format("YYYY-MM")

      const givenDate = moment(value.$d).format("YYYY-MM")
      const isTrue = newDate !== givenDate
      return isTrue
    }

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: 20
        }}
      >
        <div>
          <MonthDropdown
            handlePrev={handlePrev}
            value={value}
            handleNext={handleNext}
          />
        </div>
        <div style={{ display: "flex", gap: 50, cursor: "pointer" }}>
          <span onClick={() => isPreviousDate() && handlePrev(1)}>
            <img src={Images.backward} alt="" />
          </span>
          <span onClick={() => getFifthYearDate() && handleNext(1)}>
            <img src={Images.forward} alt="" />
          </span>
        </div>
      </div>
    )
  }

  function disabledDate(current) {
    const today = moment()
    const dayOfWeek = current.day()

    // Disable previous year dates, Saturdays (day 6), and Sundays (day 0)
    return (
      current.year() < today.year() ||
      (current.year() === today.year() && current.isBefore(today, "day")) ||
      dayOfWeek === 6 ||
      dayOfWeek === 0
    )
  }
  useEffect(() => {
    dispatch(getCalendar())
  }, [])

  const callBack = () => {
    setShowScheduleModal(false)
  }

  const dateCellRender = date => {
    return (
      <div className="table-cell-content">
        <p>{strings.CALENDER_SAFETY_MEETING}</p>
      </div>
    )
  }

  const cellRender = date => {
    if (dates.includes(moment(date.$d).format("YYYY-MM-DD"))) {
      return dateCellRender(date)
    }
    return null
  }

  const selectCell = date => {
    if(calendarData){
      if(calendarData.safety_meetings){
        setShowPasswordModal(true)
        setSelectedDate(date.$d)
      }else{
        setShowScheduleModal(date.$d)
        if (calendarData) {
          setDateId(calendarData.safety_meeting_id)
        } else {
          setDateId(false)
        }
  
      }
    }
  }



  const passwordCallBack = () => {
    setShowPasswordModal(false)
    setShowScheduleModal(selectedDate)
      if (calendarData) {
        setDateId(calendarData.safety_meeting_id)
      } else {
        setDateId(false)
      }

  }

  return (
    <CompanyLayout isActive={`${strings.CALENDAR}`}>
      <div className="upper-container">
        <h2>{strings.CALENDAR}</h2>
        <p>
          {strings.TAP_DATE_ON_CALENDAR_TO}{" "}
          <span>{strings.SCHEDULE_FIRST_SAFETY_MEETING}</span>
        </p>
      </div>

      <div className="calender-container">
        <ConfigProvider locale={lang === "es" ? esES : enEn}>
          {loading ? (
            <div className="calender-spinner-wrapper">
              <Spinner as="div" animation="border" size="xl" />
            </div>
          ) : (
            <Calendar
              locale={lang === "es" ? esES : enEn}
              onPanelChange={onPanelChange}
              onSelect={date => selectCell(date, true)}
              disabledDate={disabledDate}
              cellRender={cellRender}
              headerRender={renderCustomHeader}
            />
          )}
        </ConfigProvider>
        {/* <div className="calendar-footer">
          <div className="footer-title">{strings.IMPORTANT_NOTE_PLEASE_READ}</div>
          <div className="footer-text">
           {strings.ON_THE_FOLLOWING_SCREEN_PLEASE_ADD}
          </div>
        </div> */}
      </div>
      <ScheduleMeetingModal
        show={showScheduleModal}
        onHide={() => setShowScheduleModal(false)}
        // setEmployeeModal={() => setShowScheduleModal(false)}
        callBack={callBack}
        dateId={dateId}
        // loading={loading}
      />
      <PasswordModal
       show={showPasswordModal}
       onHide={() => {setShowPasswordModal(false)}}
       passwordCallBack={passwordCallBack}
       />
    </CompanyLayout>
  )
}

export default CompanyCalender
