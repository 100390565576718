export default {
  LOCALE: "en",
  LANGUAGES: "english",
  THE_SAFETY_TEAM: "The Safety Team",
  SELECT_USER_TYPE: "Select Your User Type.",
  COMPANY_ACCOUNT: "Company Account",
  BUSINESS_ADMIN: "Business Admin",
  EMPLOYEE: "Employee",
  LOGIN: "Login",
  SIGNUP: "Sign Up",
  SIGN_IN: "Sign In",
  USER_NAME: "user name",
  SIGN_INN: "SIGN IN",
  SIGN_UP: "SIGN UP",
  CREATE_YOUR_ACCOUNT: "Create your Account",
  COMPANY_NAME: "Company Name",
  PLEASE_NOTE: "Please Note: must be a",
  PERMANENT: "permanent",
  COMPANY_EMAIL_ADDRESS_LOWERCASE: " company email address",
  COMPANY_EMAIL_ADDRESS: "Company Email Address",
  CONFIRM_COMPANY_EMAIL_ADDRESS: "Confirm Company Email Address",
  NEW_COMPANY_EMPLOYEE: "New Employee",
  FIELD_IS_REQUIRED: "Field is required!",
  CREATE_PASSWORD: "Create password",
  CONFIRM_PASSWORD: "Confirm password",
  I_HAVE_READ: "I have read",
  TERMS_CONDITION: "Terms & Conditions",
  PRIVACY_POLICY: `Privacy Policy`,
  AND: "and",
  NEXT: "Next",
  NAME_IS_REQUIRED: "Name is required",
  EMAIL_IS_REQUIRED: "Email is required",
  PASSWORD_IS_REQUIRED: "Password is required",
  CONFIRM_PASSWORD_IS_REQUIRED: "Confirm password is required",
  EMAIL_ADDRESS: "Email address",
  OPTIONAL_EMAIL_ADDRESS: "Email address (optional)",
  PASSWORD: "Password",
  KEEP_ME_LOGGED_IN: "Keep me logged in.",
  WELCOME_BACK: "Welcome Back!",
  FORGOT_PASSWORD: "Forgot Password?",
  THANKS_FOR_DOWNLOADING: "Thanks for Downloading",
  THE_SAFETY_TEAM_APP: "The Safety Team App",
  SAFETY_TRAINING_MANDATORY: "Safety Training is Mandatory in",
  YOUR_INDUSTRY: "Your Industry!",
  THE_SAFETY_TEAM_APP_IS_MOBILE_TOOL:
    "The Safety Team App is a mobile tool that allows you to",
  DIGITALLY_AUTOMATE_THE_SCHEDULE:
    "digitally automate the scheduling, hosting, and documentation",
  SAFETY_MEETINGS_FOR_ALL_YOUR_EMPLOYEES:
    "of mandatory safety meetings for all your employees at any time and at any jobsite",
  USING_THEIR_MOBILE_PHONES_YOUR_EMPLOYEES_WILL_WATCH:
    "Using their mobile phone, your employees will watch an",
  OSHA_APPROVED_INDUSTRY_RELEVANT_FULL_MOTION_SAFETY_VIDEO:
    "OSHA approved, industry relevant, full-motion safety video",
  IN_OUR_APP_VIDEO_IS_PRE_SELECTED_TITLE_FOR_YOUR_INDUSTRY:
    "in our app. Videos are pre-selected titles for your industry and",
  STREAM_IN_THE_MOBILE_APP: "stream in the mobile app",
  ON_A_RECURRING_PRESET_SCHEDULE_EMPLOYEE_ARE_GIVE:
    "on a recurring, preset schedule. Employees are given a",
  WINDOW_48HR: "48hr window",
  TO_COMPLETE_EACH_SAFETY_TRAINING: "to complete each safety training.",
  YOUR_EMPLOYEE: "Your employee’s",
  ELECTRONIC_SIGNATURE_AND_SHORT_QUIZ: "electronic signature and a short quiz",
  ARE_REQUIRED_AFTER_EACH_2_20_MINUTE:
    "are required after each 2 to 20 minute safety video.",
  EMPLOYEE_SIGNATURE_WELL: "Employee signatures will",
  AUTOMATICALLY_POPULATE_TOGETHER: "automatically populate together",
  AN_OSHA_STANDARD_SAFETY_ATTENDANCE_FORM:
    "on an OSHA standard safety attendance form.",
  TRAINING_VIDEO: "Training Videos",
  EMPLOYEE_SIGNATURE: "Employee Signature",
  LETS_ENROLL_YOUR_COMPANY: `Let’s enroll your Company in`,
  THEN_WE_CAN_SCHEDULE_YOUR_FIRST_SAFETY_MEETING:
    "Then we can schedule your first company safety meeting.",
  WHAT_IS_YOUR_COMPANY_NAME: "What is your Company Name",
  WHAT_IS_YOUR_FULL_NAME: "What is your Full Name",
  WHAT_IS_YOUR_EMAIL_ADDRESS: "What is your Email Address",
  PHONE: "Phone (XXX) XXX-XXXX",
  HOW_MANY_EMPLOYEE: "How many Employees?",
  ENTER_EMPLOYEE_COUNT: "Enter employee count",
  FIRST_NAME: "First Name ",
  LAST_NAME: "Last Name",
  ADDRESS: "Address (street number and name)",
  ZIP: "Zip",
  STATE: "State",
  CITY: "City",
  COUNTRY: "Country",
  EXPIRATION_DATE: "Expiration data",
  SELECT_YOUR_INDUSTRY: "Select your industry",
  HOW_OFTEN_WOULD_YOU_LIKE_THE_APP_TO_HOST: `How often would you \nlike the app to host \nyour `,
  SAFETY_MEETING: "Safety Meetings?",
  RECOMMENDED_OPTION: "RECOMMENDED",
  ARE_YOU_SURE_YOU_WANT_TO_CHANGE: "Are you want to change",
  SUBSCRIPTION_PLAN: "Subscription plan?",
  BASIC: "BASIC",
  EVERY_10_BUSINESS_DAYS: "Every 10 Business Days",
  ONE_TRAINING_PER_MONTH: "One Training Per Month",
  PER_EMPLOYEE_PER_MONTH: "per employee/month",
  THIS_IS_OSHA_ACCEPTABLE_STANDARD_FOR_MANY_INDUSTRY:
    "This is an OSHA acceptable standard for many industries",
  SELECT_OPTION: "Select Option",
  NO_SUBSCRIPTION_AVAILABLE_AT_MOVEMENT:
    "No Subscription available at the moment.",
  CLOSE_MODAL: "Close Modal",
  PLAN: "PLAN",
  Plan: "Plan",
  SUBSCRIPTION_REVIEW: "Subscription Review",
  NUMBER_OF_EMPLOYEE: "Number of Employees",
  MONTHLY_SUBSCRIPTION: "Monthly Subscription",
  PAYMENT: "Payment",
  LOGIN_SUCCESSFULLY: "Login Successful!",
  ACCOUNT_DELETED: "Account Deleted!",
  BILLING: "Billing",
  PER_EMPLOYEE_CHARGE_ON_MONTHLY_BASIS:
    "per employee/charged on a monthly basis",
  CARD_NUMBER: "Card Number",
  CARD_HOLDER_NAME: "Card holder Name",
  MM_YYYY: "Expiration Date",
  CVV: "CVV",
  CONFIRM: "Confirm",
  PLEASE_ADD_SLASH: "Please add a / between the expiration month and year.",
  GOOD_TO_SEE_YOU: "Good To See You!",
  BE_PREPARED_FOR_A_SHORT_QUIZ_AFTER_THE_VIDEO:
    "Be Prepared For A Short Quiz After The Video.",
  SIGNATURE: "Signature",
  QUIZ: "Quiz",
  QUESTION: "Question",
  POINTS: "points",
  YOUR_ANSWER_IS_INCORRECT: "Your answer is incorrect.",
  YOUR_ANSWER_IS_CORRECT: "Your answer is correct.",
  NEXT_QUESTION: "Next Question",
  TOTAL_POINTS: "total points",
  FINISHED: "Finished!",
  GREAT_JOB: "Great job",
  SEE_YOU_AT_THE_NEXT_SAFETY_MEETING: "See you at the next Safety Meeting.",
  EXIT: "Exit",
  SETTINGS: "Settings",
  CHANGE_PASSWORD: "Change Password",
  TERMS_AND_CONDITIONS: "Terms & Conditions",
  SUPPORT_SEND_FEEDBACK: "Support/Send Feedback",
  SIGN_OUT: "Sign Out",
  DELETE_ACCOUNT: "Delete Account",
  YES: "Yes",
  ARE_YOU_SURE_YOU_WANT_TO_DELETE_YOUR_ACCOUNT:
    "Are you sure you want to Delete your Account?",
  CURRENT_PASSWORD: "Current Password",
  NEW_PASSWORD: "New Password",
  CANCEL: "Cancel",
  SEND_FEEDBACK: "Send Feedback",
  MESSAGE: "Message",
  SUBMIT: "Submit",
  RESET_PASSWORD: "Reset Password",
  ENTER_REGISTERED_EMAIL: "Enter registered Email",
  LEADERBOARD: "Leaderboard",
  POINT_RANKING: "Point Ranking",
  TOTAL_MEETING: "Total Meetings",
  TOTAL_EMPLOYEE: "Total Employees",
  OSHA_STANDARD_HEAD: "OSHA Standards",
  OSHA_STANDARD: "OSHA standards",
  REQUIRE_THAT: " Require That ",
  EMPLOYEE_RECEIVE_RECURRING_TRAINING:
    "Employees Receive Recurring Training So That Work Will Be Performed in a",
  SAFE_AND_HEALTHFUL: " Safe and Healthful",
  MANNER: "Manner",
  ALL_ADMIN: "All Admins",
  ADD_NEW_BUSINESS_ADMIN: "New Business Admin",
  FULL_NAME: " Your Full Name",
  CONFIRM_EMAIL_ADDRESS: "Confirm email address",
  COMPANY_EMAIL_CONFIRM_REQUIRED: "Confirm Company email is required",
  EDIT: "Edit",
  DELETE: "Delete",
  JOBSITES: "Jobsites",
  ALL_JOBSITES: "All Jobsites",
  NO_JOBSITE_AVAILABLE: "No jobsite available.",
  ADD_NEW_JOBSITES: "Add New Jobsite",
  NEW_JOBSITE: "New Jobsite",
  JOBSITE_NAME: "Jobsite Name",
  JOBSITES_ADDRESS: "Address",
  SAVE_AND_ADD: "Save & Add",
  FINISHING_ADDING: "Finished Adding",
  EDIT_NEW_JOBSITES: "Edit New Jobsite",
  SAVE_AND_EDIT: "Save & Edit",
  FINISH_EDITING: "Finished Editing",
  EMPLOYEES: "Employees",
  ALL_EMPLOYEES: "All Employees",
  NO_EMPLOYEE_AVAILABLE: "No employee available.",
  ADD_NEW_EMPLOYEE: "Add New Employee",
  NEW_EMPLOYEE: "New Employee",
  UPLOAD_TEMPLATE: "Upload Template",
  INPUT_SINGLE_USER: "Input Single User",
  EMPLOYEE_NAME: "Employee Name",
  CONFIRM_PHONE: "Confirm Phone (XXX) XXX-XXXX",
  REVIEW: "Review",
  EDIT_EMPLOYEE: "Edit Employee",
  CARD: "Card",
  YOU_HAVE_NO_ADDED_ANY_CARD_YET: "You have not added any card yet.",
  PAYMENTS: "Payments",
  ALL_CARDS: "All Cards",
  ADD_CARDS: "Add Card",
  ADD_CARD_DETAILS: "Add Card Details",
  ATTENDANCE_FORM: "Attendance Forms",
  ALL_ATTENDANCE_FORM: "All Attendance Forms",
  CALENDAR: "Calendar",
  TAP_DATE_ON_CALENDAR_TO: "Tap date on Calendar to",
  SCHEDULE_FIRST_SAFETY_MEETING: "schedule first Safety Meeting.",
  IMPORTANT_NOTE_PLEASE_READ: " Important Note, Please Read For Best Results",
  CALENDAR_SCREEN_CONTENT:
    "Inform your employees to watch the videos when they have a strong cell signal. if employees have bad signal, they can download the video from the app. Download when connected to WiFi, then watch remotely later. Please regularly remind all employees that participation is mandatory for OSHA compliance.",
  SCHEDULE_SAFETY_MEETING: "Schedule Safety Meeting",
  SCHEDULE: "Schedule",
  MOBILE_NUMBER: "Mobile Number",
  YOU_PICKED_THE_DATE: "You picked the date",
  ALL_FEATURE_AUTOMATICALLY_SCHEDULE: `All future safety meetings will be \nautomatically scheduled`,
  SAVE_CHANGES: "Save Changes",
  EDIT_BUSINESS_ADMIN: "Edit Business Admin",
  ARE_YOU_SURE_YOU_WANT_TO_DELETE: "Are you sure you want to Delete Admin",
  NO: "No",
  NAME: "Name",
  DOWNLOAD_TEMPLATE: "Download Template",
  FILTER_SEARCH: "Filter Search",
  HOME: "Home",
  LEADER_BOARD: "Leaderboard",
  POINTS_1000: "1000 Points",
  SEND: "Send",
  CLEAR: "Clear",
  DELETE_CARD: "Delete Card",
  PLEASE_ENTER_A_VALID_EMAIL_ADDRESS: "Please enter a valid email address",
  PASSWORD_MUST_MATCH: "Passwords must match",
  EMAIL_MUST_MATCH: "Email must match",
  PLEASE_ACCEPT_TERMS_AND_CONDITIONS_FIRST:
    "Please accept Terms & Conditions and Privacy Policy first.",
  PHONE_NUMBER_IS_REQUIRED: "Phone number is required",
  PHONE_NUMBER_IS_INCORRECT: "Phone number is incorrect",
  ADD_PHONE_NUMBER_WITHOUT_COUNTRY_CODE:
    "Please add phone number without country code",
  OWNER_NAME_IS_REQUIRED: "Owner Name is required",
  INDUSTRY_IS_REQUIRED: "Industry is required",
  NO_OFF_EMPLOYEE_IS_REQUIRED: "No of Employee is required",
  ADDRESS_IS_REQUIRED: "Address is required",
  ZIP_CODE_IS_REQUIRED: "Zip code is required",
  ZIP_CODE_IS_ONLY_A_NUMBER: "Zip code can only be a number",
  STATE_IS_REQUIRED: "State is required",
  CITY_IS_REQUIRED: "City is required",
  CARD_NUMBER_IS_REQUIRED: "Card Number is required",
  CVV_IS_REQUIRED: "CVV is required",
  NUMBER_IS_REQUIRED: "Accept only number",
  PLEASE_ADD_A_SLASH_BETWEEN_EXPIRATION_MONTH_AND_YEAR:
    "Please add the expiration month and year.",
  UPDATE: "Update",
  PHONE_MUST_MATCH: "Phone must match",
  CONFIRM_PHONE_NUMBER_REQUIRED: "Confirm Phone Number is required",
  CONFIRM_EMAIL_IS_REQUIRED: "Confirm email is required",
  NEW_PASSWORD_MUST_NOT_MATCH_CURRENT_PASSWORD:
    "New password must not match with current password",
  TOKEN_MUST_BE_AT_LEAST_4_LONG: "Token must be at least 4 characters long",
  TOKEN_IS_REQUIRED: "Token is required",
  SUCCESSFULLY_SIGN_OUT: "Successfully Sign Out!",
  PLEASE_ENTER_CURRENT_PASSWORD: "Please enter current Password",
  MESSAGE_IS_REQUIRED: "Message is required",
  PLEASE_ADD_YOUR_SIGNATURE_FIRST: "Please Add Your Signature First.",
  PLEASE_SELECT_YOUR_JOBSITE_FIRST: "Please select a jobsite first.",
  FEEDBACK_SUCCESSFULLY: "Feedback successfully!",
  EMAIL_IS_NOT_VALID: "Email is not valid",
  FROM: "From",
  TO: "To",
  NO_VIDEO_FOR_TODAY: "No video tutorial for today.",
  ADD_SIGNATURE: "Add Signature",
  CHANGE_PAYMENT_METHOD: "Change Payment Method",
  YOUR_FULL_NAME_IS_REQUIRED: "Your Full Name is required",
  THE_SAFETY_TEAM_APP_THANKYOU: "The Safety Team App",
  NO_DATA_FOUND: "No Data Found.",
  SELECT_DATE: "Select date",
  FEEDBACK_SENT_SUCCESSFULLY: "Feedback sent successfully!",
  PASSWORD_HAS_BEEN_RESET_SUCCESSFULLY: "Password has been reset successfully!",
  PASSWORD_HAS_BEEN_CHANGED_SUCCESSFULLY:
    "Password has been changed successfully!",
  INCORRECT_NUMBER: "The card number is incorrect.",
  INVALID_NUMBER: "The card number is not a valid credit card number.",
  INVALID_EXPIRY_MONTH: "The card's expiration month is invalid.",
  INVALID_EXPIRY_YEAR: "The card's expiration year is invalid.",
  INVALID_CVC: "The card's security code is invalid.",
  EXPIRED_CARD: "The card has expired.",
  INCORRECT_CVC: "The card's security code is incorrect.",
  INCORRECT_ZIP: "The card's zip code failed validation.",
  CARD_DECLINED: "The card was declined.",
  MISSING: "There is no card on a customer that is being charged.",
  PROCESSING_ERROR: "An error occurred while processing the card.",
  RATE_LIMIT:
    "An error occurred due to requests hitting the API too quickly. Please let us know if you're consistently running into this error.",
  DATA_HAS_BEEN_UPLOADED_SUCCESSFULLY: "Data has been uploaded successfully!",
  COMPANY_NAME_REQUIRED: "Company Name Required",
  COMPANY_EMAIL_REQUIRED: "Company email is required",
  YOU_MUST_ACCEPT_THE_TERMS_AND_CONDITIONS:
    "You must accept the terms and conditions",
  SUPER_ADMIN: "Super Admin",
  PHONE_NUMBER: "Phone Number",
  CONFIRM_PHONE_NUMBER: "Confirm Phone Number",
  EMAIL: "Email",
  NEW_ADMIN: "New Admin",
  USER_LIST: "User List",
  COMPANIES: "Companies",
  ADMINS: "Admins",
  ON_THE_FOLLOWING_SCREEN_PLEASE_ADD:
    " On the following screens, please add all employees' names and mobile phone numbers. You can use our downloadable template to upload all employee information at once.",
  TRAINEE_NAME: "Trainee Name",
  DATE: "Date",
  JOB_NAME: "Job Name",
  ADDRESS_JOB: "Address",
  SEARCH: "Search",
  ALL_USERS: "All Users",
  PLACE: "Place",
  ADMIN_NAME: "Admin Name",
  ADMIN_EMAIL: "Admin Email",
  ADMIN_MOBILE: "Admin Mobile",
  FEEDBACK: "Feedback",
  ALL_FEEDBACK: "All Feedback",
  VIEW: "View",
  VIDEOS: "Videos",
  QUESTIONS: "Questions",
  SELECT_INDUSTRY: "Select Industry",
  ADD_QUESTIONS: "Add Questions",
  USERS: "Users",
  SUBSCRIPTIONS: "Subscriptions",
  INDUSTRIES: "Industries",
  ANALYTICS: "Analytics",
  NEW_USER: "New User",
  GENERAL: "General",
  ALL_FUTURE_SAFETY_MEETING_WILL_BE: "All future safety meetings will be",
  AUTOMATICALLY_SCHEDULED: "automatically scheduled",
  ADD_USER: "Add User",
  YOUR_ACCOUNT: "Your Account",
  ARE_YOU_SURE_YOU_WANT_TO: "Are you sure you want to",
  DELETE_YOUR_ACCOUNT: " delete your account?",
  DELETE_EMPLOYEE: "Delete Employee",
  YOU_CANT_UNDO_THIS_ACTION: "You can’t undo this action",
  ROLE_NUMBER_IS_REQUIRED: "Role number is Required",
  EDIT_USER: "Edit User",
  ADDRESS_LINE: "Address Line",
  EDIT_JOBSITE: "Edit Jobsite",
  MOBILE_NUMBER_IS_REQUIRED: "Mobile Number is required!",
  EMPLOYEES_MUST_BE_A_POSITIVE_NUMBER: "Employees must be a positive number",
  EMPLOYEES_MUST_BE_AN_INTEGER: "Employees must be an integer",
  EMPLOYEES_CANNOT_BE_NEGATIVE: "Employees cannot be negative",
  EMPLOYEE_IS_REQUIRED: "Employee is required!",
  INVALID_ZIP_CODE_FORMAT: "Invalid zip code format",
  INDUSTRY_TITLE_IS_REQUIRED: "Industry title is required",
  CARD_HOLDER_NAME_IS_REQUIRED: "Card Holder name is required",
  EXPIRATION_DATE_IN_THIS_FORMAT_MM_YYYY:
    "Expiration date in this formate MM/YYYY",
  MAX_LENGTH_EXCEEDED: "Max length exceeded",
  FULL_NAME_IS_REQUIRED: "Full Name is required",
  PLEASE_ENTER_A_MESSAGE: "Please enter a message",
  INPUT_ANSWER_NO_1: "Input Answer No. 1",
  ADD_MORE: "Add More",
  INPUT_TRUE_ANSWER: "Input true answer",
  ANSWER_IS_REQUIRED: "Answer is required",
  TRUE: "True",
  FALSE: "False",
  SELECT_ANSWER: "Select Answer",
  INPUT_ANSWER_NO: "Input Answer No.",
  EDIT_QUESTION: "Edit Question",
  ADD_QUESTION: "Add Question",
  VIEW_QUESTION: "View Question",
  SELECT_ANSWER_TYPE: "Select answer type",
  ADD_BUSINESS_ADMIN: "Add Business Admin",
  PREV: "Prev",
  VIDEO_TITLE: "Video Title",
  ANSWER: "Answer",
  VIEWS: "Views",
  ADD_NEW: "Add New",
  NEW_INDUSTRY: "New Industry",
  NEW_SUB_INDUSTRY: "New Sub-Industry",
  INDUSTRY_TITLE: "Industry Title",
  SUB_INDUSTRY_TITLE_OPTIONAL: "Sub-Industry Title (Optional)",
  SUB_INDUSTRY_TITLE: "Sub-Industry Title",
  ROW_PER_PAGE: "Row per page",
  SUB_INDUSTRY_TITLE_IS_REQUIRED: "Sub industry title is required",
  MORE: "More",
  TURN_ON_0FF: "Turn On/Off",
  DELETE_USER: "Delete User",
  ROLE: "Role",
  FLAG: "Flag",
  EDIT_INDUSTRY: "Edit Industry",
  SUB_INDUSTRY_IS_REQUIRED: "Sub industry is required",
  EDIT_PLAN: "Edit Plan",
  PLAN_TAG_EG_RECOMMENDED: "Plan tag e.g. Recommended (Optional)",
  SUBSCRIBED_USERS: "Subscribed Users",
  EDIT_VIDEO: "Edit Video",
  CHANGE_PLAN: "Change Plan",
  DELETE_VIDEO: "Delete Video",
  LANGUAGE_SECTION_IS_REQUIRED: "Language section is required",
  VIDEO_TITLE_IS_REQUIRED: "Video title is required",
  APP_DOWNLOAD: "App Downloads",
  SUBSCRIPTION_EARNINGS: "Subscription Earnings",
  CURRENT_PASSWORD_IS_REQUIRED: "Current password is required!",
  NEW_PASSWORD_IS_REQUIRED: "New password is required!",
  INVALID_CARD_NUMBER: "Invalid Card number",

  //
  VIDEO_IS_REQUIRED: "Video is Required",
  VIDEO_NUMBER_MUST_BE_A_VALID_NUMBER: "Video number must be a valid number",
  VIDEO_NUMBER_IS_REQUIRED: "Video Number is required",
  VIDEO_NUMBER_CANNOT_BE_NEGATIVE: "Video Number cannot be negative",
  NO_SPECIAL_CHARACTERS: "no-special-characters",
  NEW_VIDEO: "New Video",
  SELECT_YOUR_SUB_INDUSTRY: "Select your Sub-Industry",
  SELECT_LANGUAGE_SECTION: "Select language section",
  VIDEO_NUMBER: "Video Number",
  UPLOAD_FILE: "Upload File",
  UPLOAD_A_VIDEO: "Upload a Video",
  ADD_VIDEO: "Add Video",
  DELETE_JOBSITE: "Delete Jobsite",
  INVALID_PHONE_NUMBER: "Invalid Phone Number",
  VIDEO_TITLE_TOO_LONG: "Video Title Too Long",
  DELETE_INDUSTRY: "Delete Industry",
  DELETE_PLAN: "Delete Plan",
  TITLE_NUMBER_IS_REQUIRED: "Title number is required",
  PRICE_IS_REQUIRED: "Price is required",
  PRICE_IS_MUST_BE_POSITIVE_NUMBER: "Price must be a positive number",
  DESCRIPTION_IS_REQUIRED: "Description is required",
  SELECT_COLOR: "Select Color",
  NEW_PLAN: "New Plan",
  TITLE: "Title",
  PRICE: "Price",
  PRICE_RULE_E_G_MONTHLY: "Price rule e.g. Monthly",
  DESCRIPTION_DIVIDE_SECTION:
    "Description (Divide sections with bullet points)",
  ADD_PLAN: "Add Plan",
  ESTIMATED_REVENUE: "Estimated Revenue",
  SOMETHING_WENT_WRONG: "Something went wrong!",
  OTP_IS_REQUIRED: "OTP is required",
  OTP: "OTP",
  YOUR_PASSWORD_HAS_BEEN_RESET_SUCCESSFULLY:
    "Your password has been reset successfully",
  DATA_HAS_BEEN_UPDATED_SUCCESSFULLY: "Data has been uploaded successfully",
  ACCOUNT_DELETED_SUCCESSFULLY: "Account Deleted successfully!",
  MESSAGE_SENT_SUCCESSFULLY: "Message sent successfully!",
  SUB_INDUSTRY_ADDED_SUCCESSFULLY: "Sub-Industry added Successfully!",
  INDUSTRY_ADDED_SUCCESSFULLY: "Industry added Successfully!",
  INDUSTRY_DELETED_SUCCESSFULLY: "Industry deleted Successfully!",
  INDUSTRY_EDITED_SUCCESSFULLY: "Industry edited Successfully!",
  MARKS_AT_LEAST_ONE_OPTION_AS_CORRECT: "Mark at least one option as correct",
  QUESTION_ADDED_SUCCESSFULLY: "Question added Successfully!",
  QUESTION_UPDATED_SUCCESSFULLY: "Question updated successfully!",
  VIDEO_ADDED_SUCCESSFULLY: "Video added Successfully!",
  VIDEO_DELETED_SUCCESSFULLY: "Video deleted Successfully!",
  VIDEO_EDITED_SUCCESSFULLY: "Video edited Successfully!",
  SIGNUP_SUCCESSFULLY: "Sign Up Successful!",
  CVV_ONLY_NUMBERS: "CVV only numbers",
  CVV_MAX_LENGTH: "CVV max length exceeded",
  NEW_COMPANY_JOBSITE: "New Jobsite",
  CONFIRM_PASSWORD: "Confirm Password",
  QUESTION_IS_REQUIRED: "Question is required",
  QUESTION_TYPE_IS_REQUIRED: "Question type is required",
  OPTION_IS_REQUIRED: "Option is required",
  CURRENT_PLAN_IS: "Current plan is",
  SAFETY_MEETING: "Safety Meetings",
  SAFETY_MEETINGS: "Safety Meetings",
  OTP_IS_INVALID: "OTP is Invalid",
  PASSWORD_IS_INVALID: "Password is Invalid",
  MARK_AT_LEAST_ONE_CORRECT_OPTION: "Mark at least one option as correct",
  NEW_EMPLOYEE_ADDED: "New Employee Added Successfully",
  ARE_YOU_SURE_YOU_WANT_TO_SIGN_OUT: "Are you sure you want to",
  CLIENT_NAME: "Client Name",
  ST: "st",
  ND: "nd",
  RD: "rd",
  JANUARY: "January",
  FEBRUARY: "February",
  MARCH: "March",
  APRIL: "April",
  MAY: "May",
  JUNE: "June",
  JUlY: "July",
  AUGUST: "August",
  SEPTEMBER: "September",
  OCTOBER: "October",
  NOVEMBER: "November",
  DECEMBER: "December",
  WEEkS: "Weeks",
  WEEK: "Week",
  DAYS: "Days",
  DAY: "Day",
  YEAR: "Year",
  MONTH: "Month",
  SECONDS: "Seconds",
  MINUTES: "Minute",
  YEARS: "year",
  HOURS: "Hours",
  HOUR: "Hour",
  MONTHS: "Months",
  AGO: "ago",
  EDIT_COMPANY_JOBSITE: "Edit Jobsite",
  ADD_ADMIN: "Add Admin",
  ADD_ADMIN_SUCCESSFULLY: "Admin added Successfully!",
  DELETE_ADMIN_SUCCESSFULLY: "Admin deleted Successfully!",
  EDIT_ADMIN_SUCCESSFULLY: "Admin edited Successfully!",
  ADMIN_DISABLED_SUCCESSFULLY: "Admin disabled Successfully!",
  ADMIN_ENABLED_SUCCESSFULLY: "Admin enabled Successfully!",
  EDIT_EMPLOYEE_SUCCESSFULLY: "Employee Edited Successfully!",
  DELETE_EMPLOYEE_SUCCESSFULLY: "Employee Deleted Successfully!",
  ADD_JOBSITES_SUCCESSFULLY: "Jobsite Added Successfully!",
  EDIT_JOBSITES_SUCCESSFULLY: "Jobsite Edited Successfully!",
  DELETE_JOBSITES_SUCCESSFULLY: "Jobsite Deleted Successfully!",
  ADD_CARD_SUCCESSFULLY: "Card Added Successfully!",
  EDIT_CARD_SUCCESSFULLY: "Card Edited Successfully!",
  DELETE_CARD_SUCCESSFULLY: "Card Deleted Successfully!",
  PLAN_TAG_EG_RECOMMENDED_OPTIONAL: "Plan tag e.g. Recommended (Optional)",
  DESCRIPTION_DIVIDE_SECTION_WITH_BULLET:
    "Description (Divide sections with bullet points)",
  PLAN_CHANGED_SUCCESSFULLY: "Plan changed Successfully!",
  USER_DELETED_SUCCESSFULLY: "User deleted Successfully!",
  DELETE_ADMIN: "Delete Admin",
  OPTIONAL: "(Optional)",
  MOBILE: "Mobile",
  PASSWORD_IS_INCORRECT: "Password is incorrect",
  PLEASE_ENTER_PASSWORD: "Please Enter Password",
  DOWNLOAD_SHEET: "Download Attendance Sheet",
  EMPLOYEES_WITH_NO_DUPLICATION:
    "Employees with no duplications are added successfully!",
  NO_EMPLOYEE_ADDED: "Employees are not added!",
  ALL_EMPLOYEES_ADDED: "All Employees are added successfully!",
  PARTNER_CODE: "Partner Code",
  COUPON_ID_IS_REQUIRED: "Coupon Id is required",
  DISCOUNT_PERCENTAGE_IS_REQUIRED: "Discount percentage is required",
  COUPON_ID: "Coupon Id",
  DISCOUNT_PERCENTAGE: "Discount Percentage",
  VALUE_IS_NOT_VALID: "Value is not valid",
  ADD_COUPON: "Add Coupon",
  COUPON_ADDED_SUCCESSFULLY: "Coupon added successfully",
  COUPON_CODE: "Coupon Code",
  ADMIN_FULL_NAME: "Admin Full Name",
  ADMIN_FULL_NAME_IS_REQUIRED: "Admin Full Name is required",
  CALENDER_SAFETY_MEETING: "Safety Meeting",
  SUB_INDUSTRY_DELETED_SUCCESSFULLY: "Sub-industry deleted Successfully!",
  FOR_INTERNAL_USE_ONLY: "FOR INTERNAL USE ONLY",
  COUPON_LIMIT_IS_REQUIRED: "Coupon Limit is required",
  COUPON_LIMIT: "Coupon Limit",
  SELECT_DATE_FILTER_RECORD: "Select Dates to Filter Record",
  NUMBER_OF_MONTHS_REQUIRED: "Number of Months are required",
  NUMBER_OF_MONTHS: "Number of Months",
  VERIFY_OTP:'Verify OTP',
}
