export const GET_ADMINS = 'GET_ADMINS'
export const GET_ADMINS_SUCCESS = 'GET_ADMINS_SUCCESS'
export const GET_ADMINS_FAILURE='GET_ADMINS_FAILURE'

export const ADD_ADMIN = 'ADD_ADMIN'
export const ADD_ADMIN_SUCCESS = 'ADD_ADMIN_SUCCESS'
export const ADD_ADMIN_FAILURE = 'ADD_ADMIN_FAILURE'

export const DELETE_ADMIN = 'DELETE_ADMIN'
export const DELETE_ADMIN_SUCCESS = 'DELETE_ADMIN_SUCCESS'
export const DELETE_ADMIN_FAILURE = 'DELETE_ADMIN_FAILURE'

export const EDIT_ADMIN = 'EDIT_ADMIN'
export const EDIT_ADMIN_SUCCESS = 'EDIT_ADMIN_SUCCESS'
export const EDIT_ADMIN_FAILURE = 'EDIT_ADMIN_FAILURE'

export const ADMIN_DISABLE = 'ADMIN_DISABLE'
export const ADMIN_DISABLE_SUCCESS = 'ADMIN_DISABLE_SUCCESS'
export const ADMIN_DISABLE_FAILURE = 'ADMIN_DISABLE_FAILURE'

export const GET_TOTAL_MEETINGS = 'GET_TOTAL_MEETINGS'
export const GET_TOTAL_MEETINGS_SUCCESS = 'GET_TOTAL_MEETINGS_SUCCESS'
export const GET_TOTAL_MEETINGS_FAILURE = 'GET_TOTAL_MEETINGS_FAILURE'