import Modal from "react-bootstrap/Modal"
import { Images } from "../../../../../theme/Images"
import "./style.scss"
import Button from "../../../../../components/Button"
import EditQuestionModal from "../EditQuestionModal"
import strings from "../../../../../translation"
function ViewQuestionModal(props) {
  const newOption = () => {
    if (props.question?.options?.length) {
      let answers = ''
      let separator = false
      props.question.options.map(el=>{
        if(el.is_answer){
          answers = `${answers}${separator?',':''} ${el?.option}`
          separator=true
        }
      })
      return answers
    } else {
      return "Select Answer"
    }
  }

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="main-container"
    >
      <Modal.Body className="modal-container">
        <div className="close-icon-container">
          <img
            src={Images.close}
            onClick={props.onHide}
            className="modal-close-icon"
            alt="closeicon"
          ></img>
        </div>
        <div className="header-container">
          <h1 className="title">{strings.VIEW_QUESTION}</h1>
        </div>
        <div className="content-container">
          <p className="video-title">{strings.VIDEO_TITLE}</p>
          <p className="question-number">{strings.QUESTION}: {props.questionIndex + 1}</p>
          <p className="question">{props.question.question}</p>
          <p className="answer-title">{strings.ANSWER}:</p>
          <p className="answer">{newOption()}</p>
          <p className="question-type">{props.question?.question_type}</p>
        </div>
        <div className="button-container">
          <Button
            onClick={() => {
              props.setViewModal(false)
              props.setEditModal(true)
            }}
            className="full-btn board-btn"
            title={strings.EDIT}
          />
          <div className="d-flex justify-content-between">
            <Button
              disabled={!props.questionIndex >= 1}
              onClick={props.handlePrev}
              title={strings.PREV}
            />
            <Button
              disabled={!(props.questionIndex < props.totalQuestions - 1)}
              onClick={props.handleNext}
              title={strings.NEXT}
            />
          </div>
        </div>
      </Modal.Body>
      <EditQuestionModal />
    </Modal>
  )
}

export default ViewQuestionModal
