import {
  GET_ADMINS,
  GET_ADMINS_SUCCESS,
  GET_ADMINS_FAILURE,
  ADD_ADMIN,
  ADD_ADMIN_SUCCESS,
  ADD_ADMIN_FAILURE,
  DELETE_ADMIN,
  DELETE_ADMIN_SUCCESS,
  DELETE_ADMIN_FAILURE,
  EDIT_ADMIN,
  EDIT_ADMIN_SUCCESS,
  EDIT_ADMIN_FAILURE,
  ADMIN_DISABLE,
  ADMIN_DISABLE_SUCCESS,
  ADMIN_DISABLE_FAILURE,
  GET_TOTAL_MEETINGS,
  GET_TOTAL_MEETINGS_SUCCESS,
  GET_TOTAL_MEETINGS_FAILURE

} from "./types"

const initialState = {
  allAdminsData: false,
  getloading: false,
  error: null,
  addAdminData: false,
  addLoading: false,
  deleteAdminData: false,
  deleteLoading:false,
  editAdminData:false,
  editLoading:false,
  disableAdminData: false,
  disableLoading:false,
  totalMeetingsData:false,
  getMeetingsLoading:false,
}

export default function getAllAdmins(state = initialState, action) {
  switch (action.type) {
    case GET_ADMINS:
      return {
        ...state,
        getloading: true
      }
    case GET_ADMINS_SUCCESS:
      return {
        ...state,
        getloading: false,
        allAdminsData: action.data
      }

    case GET_ADMINS_FAILURE:
      return {
        ...state,
        getloading: false,
        error: action.error
      }
    case ADD_ADMIN:
      return {
        ...state,
        addLoading: true
      }
    case ADD_ADMIN_SUCCESS:
      return {
        ...state,
        addLoading: false,
        addAdminData: action.data
      }

    case ADD_ADMIN_FAILURE:
      return {
        ...state,
        addLoading: false,
        error: action.error
      }
      case DELETE_ADMIN:
        return {
          ...state,
          deleteLoading: true
        }
      case DELETE_ADMIN_SUCCESS:
        return {
          ...state,
          deleteLoading: false,
          deleteAdminData: action.data
        }
  
      case DELETE_ADMIN_FAILURE:
        return {
          ...state,
          deleteLoading: false,
          error: action.error
        }
        case EDIT_ADMIN:
          return {
            ...state,
            editLoading: true
          }
        case EDIT_ADMIN_SUCCESS:
          return {
            ...state,
            editLoading: false,
            editAdminData: action.data
          }
    
        case EDIT_ADMIN_FAILURE:
          return {
            ...state,
            editLoading: false,
            error: action.error
          }
          case ADMIN_DISABLE:
            return {
              ...state,
              disableLoading: true
            }
          case ADMIN_DISABLE_SUCCESS:
            return {
              ...state,
              disableLoading: false,
              disableAdminData: action.data
            }
      
          case ADMIN_DISABLE_FAILURE:
            return {
              ...state,
              disableLoading: false,
              error: action.error
            }
            case GET_TOTAL_MEETINGS:
              return {
                ...state,
                getMeetingsLoading: true
              }
            case GET_TOTAL_MEETINGS_SUCCESS:
              return {
                ...state,
                getMeetingsLoading: false,
                totalMeetingsData: action.data
              }
        
            case GET_TOTAL_MEETINGS_FAILURE:
              return {
                ...state,
                getMeetingsLoading: false,
                error: action.error
              }
    default:
      return state
  }
}
