import { call, all, put, takeLatest } from "redux-saga/effects"
import { BASE_URL } from "../../../../config/app"
import UXR from "../../../../utils/XHR"
import {
  ADD_QUESTIONS,
  GET_QUESTIONS,
  GET_QUESTION_INDUSTRY_ID,
  GET_QUESTION_VIDEOS,
  EDIT_QUESTION
} from "./types"
import {
  getQuestion,
  getQuestionSuccess,
  getQuestionFailure,
  addQuestionSuccess,
  addQuestionFailure,
  getQuestionIndustryIdSuccess,
  getQuestionIndustryIdFailure,
  getQuestionVideoSuccess,
  getQuestionVideoFailure,
  editQuestionSuccess,
  editQuestionFailure
} from "./actions"
import { toast } from "react-toastify"
import strings from "../../../../translation"

async function getQuestionApi(id, page, pageSize) {
  const token = await localStorage.getItem("token")
  const URL = `${BASE_URL}/api/v1/web-videos/?industry=${id}&page_size=${
    pageSize * 3
  }&page=${page}`
  const options = {
    headers: {
      "content-type": "application/json",
      Authorization: `Token ${token}`,
      Accept: "application/json"
    },
    method: "GET"
  }
  return UXR(URL, options)
}

function* getQuestions({ id, page, pageSize }) {
  try {
    const res = yield call(getQuestionApi, id, page, pageSize)
    yield put(getQuestionSuccess(res.data))
  } catch (e) {
    yield put(getQuestionFailure(e.response))
  }
}

async function addQuestionsApi(data) {
  const token = await localStorage.getItem("token")
  const URL = `${BASE_URL}/api/v1/questions/`
  const options = {
    headers: {
      "content-type": "application/json",
      Authorization: `Token ${token}`,
      Accept: "application/json"
    },
    method: "POST",
    data
  }
  return UXR(URL, options)
}
function* addQuestions({ data, closeModal, page, pageSize, industry }) {
  try {
    const res = yield call(addQuestionsApi, data)
    yield put(getQuestion(industry, page, pageSize))
    closeModal()
    toast.success(`${strings.QUESTION_ADDED_SUCCESSFULLY}`)
    yield put(addQuestionSuccess(res.data))
  } catch (e) {
    yield put(addQuestionFailure(e.response))
  }
}
async function getQuestionIndustryIdApi() {
  const token = await localStorage.getItem("token")
  const URL = `${BASE_URL}/api/v1/industry_category/?industry`
  const options = {
    headers: {
      "content-type": "application/json",
      Authorization: `Token ${token}`,
      Accept: "application/json"
    },
    method: "GET"
  }
  return UXR(URL, options)
}

function* getQuestionIndustryId({}) {
  try {
    const res = yield call(getQuestionIndustryIdApi)
    yield put(getQuestionIndustryIdSuccess(res.data))
  } catch (e) {
    yield put(getQuestionIndustryIdFailure(e.response))
  }
}

async function getQuestionVideoApi() {
  const token = await localStorage.getItem("token")
  const URL = `${BASE_URL}/Api/v1/questions`
  const options = {
    headers: {
      "content-type": "application/json",
      Authorization: `Token ${token}`,
      Accept: "application/json"
    },
    method: "GET"
  }
  return UXR(URL, options)
}

function* getQuestionVideo() {
  try {
    const res = yield call(getQuestionVideoApi)
    yield put(getQuestionVideoSuccess(res.data))
  } catch (e) {
    yield put(getQuestionVideoFailure(e.response))
  }
}

async function editQuestionsApi(id, data) {
  const token = await localStorage.getItem("token")
  const URL = `${BASE_URL}/api/v1/questions/${id}/`
  const options = {
    headers: {
      "content-type": "application/json",
      Authorization: `Token ${token}`,
      Accept: "application/json"
    },
    method: "PUT",
    data
  }
  return UXR(URL, options)
}

function* editQuestions({ id, data, closeModal, page, pageSize, industryId }) {
  try {
    const res = yield call(editQuestionsApi, id, data)
    yield put(getQuestion(industryId, page, pageSize))
    closeModal()
    toast.success(`${strings.QUESTION_UPDATED_SUCCESSFULLY}`)
    yield put(editQuestionSuccess(res.data))
  } catch (e) {
    yield put(editQuestionFailure(e.response))
  }
}

export default all([
  takeLatest(GET_QUESTIONS, getQuestions),
  takeLatest(ADD_QUESTIONS, addQuestions),
  takeLatest(GET_QUESTION_INDUSTRY_ID, getQuestionIndustryId),
  takeLatest(GET_QUESTION_VIDEOS, getQuestionVideo),
  takeLatest(EDIT_QUESTION, editQuestions)
])
