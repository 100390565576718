import React, { useEffect } from "react"
import CompanyLayout from "../../../layout/CompanyLayout"
import "./style.scss"
import SiteTable from "./components/SiteTable"
import { useDispatch, useSelector } from "react-redux"
import { getTotalMeetings } from "./redux/actions"
import strings from "../../../translation"


const CompanyHome = () => {
  const user = useSelector(state => state.login.loginInfo)
  const meetingsData = useSelector(state => state.Home.totalMeetingsData)
  const dispatch = useDispatch()

  useEffect(()=>{
    dispatch(getTotalMeetings())
  },[])

  return (
    <CompanyLayout isActive={`${strings.HOME}`}>
      <div className="company-home-section">
        <div className="page-title">{strings.HOME}</div>
        <div className="company-home-details-wrapper">
          <div className="company-home-details details-left">
            <div className="section-details-wrapper">
              <div className="title">{strings.TOTAL_MEETING}</div>
              <div className="number">{meetingsData?.total_meeting || '0'}</div>
            </div>
            <div className="section-details-wrapper">
              <div className="title">{strings.TOTAL_EMPLOYEE}</div>
              <div className="number">{meetingsData?.total_employee || '0'}</div>
            </div>
          </div>
          <div className="company-home-details details-right">
            <div className="title">{strings.OSHA_STANDARD_HEAD}</div>
            <p>
              {" "}
              <span className="yellow">{strings.OSHA_STANDARD}</span> {strings.REQUIRE_THAT} 
             {strings.EMPLOYEE_RECEIVE_RECURRING_TRAINING}
            <span className="yellow">{strings.SAFE_AND_HEALTHFUL}</span>{" "}
              {strings.MANNER}
            </p>
          </div>
        </div>
        {user.users.user_type === "company" && (
          <div className="company-home-table-wrapper">
            <SiteTable />
          </div>
        )}
      </div>
    </CompanyLayout>
  )
}

export default CompanyHome
